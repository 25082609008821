import type { IProduct, IPromoProduct } from "@magnit/unit-catalog/src/unit-catalog.types";
import { formatPromoProduct } from "@magnit/unit-catalog/src/utils/converter";
import type {
  IOriginalPromotionsResponse,
  IRequestPromotionsByType,
  IRequestPromotionsParams,
  IRequestPromotionsSearch,
  ITransformedPromotionsResponse,
} from "~/typings/api/promo";
import { urls } from "~/api/config";

export default () => {
  const storesStore = useStoresStore();

  const appendUrl = (promo: IPromoProduct, storeCode: string) => {
    const baseUrl = Routes.PromoProduct;
    const url = `${baseUrl}/${promo.id}-${promo.slug}?shopCode=${storeCode}`;
    return { ...promo, url };
  };

  const transformItems = (items: IPromoProduct[]) =>
    items
      ?.map((product) => appendUrl(product, storesStore.requestShopCode))
      .map((product) => formatPromoProduct(product)) || [];

  const getPaginationByQuery = (
    query: Ref<IRequestPromotionsParams> | IRequestPromotionsParams,
  ) => ({
    pageSize: toValue(query)?.limit,
    page: Math.ceil((toValue(query).offset || 0) / (toValue(query).limit || 1)) + 1,
  });

  const requestPromotions = (
    query: Ref<IRequestPromotionsParams> | IRequestPromotionsParams,
    key?: string,
  ) => {
    return useTransport<IOriginalPromotionsResponse, ITransformedPromotionsResponse>(
      urls.promo.products,
      {
        query,
        transform: (data) => ({
          goods: transformItems(data.data),
          pagination: {
            total: data.total,
            ...getPaginationByQuery(query),
          },
        }),
        key,
      },
    );
  };

  const requestPromotionsItem = (promoId: MaybeRef<string>) => {
    return useTransport<IPromoProduct>(urls.promo.item, {
      lazy: import.meta.client,

      query: computed(() => ({
        promoId: toValue(promoId),
        storeCode: storesStore.requestShopCode,
      })),
    });
  };

  const requestPromotionsSearch = (query: IRequestPromotionsSearch, key?: string) => {
    return useTransport<
      {
        data: IPromoProduct[];
        total: number;
      },
      IProduct[]
    >(urls.promo.search, {
      query: computed(() => ({
        ...query,
        storeCode: storesStore.requestShopCode,
      })),
      transform: (data) => transformItems(data.data),
      key,
    });
  };

  const requestPromotionsByType = (query: IRequestPromotionsByType) => {
    return useTransport<IOriginalPromotionsResponse, ITransformedPromotionsResponse>(
      urls.promo.type,
      {
        lazy: import.meta.client,
        query: computed(() => ({
          adult: true,
          type: query.type,
          limit: query.limit,
          categoryId: query.categoryId,
          storeId: query.storeId,
          offset: query.offset,
          storeCode: query.storeCode ?? storesStore.requestShopCode,
        })),
        transform: (data) => ({
          goods: transformItems(data.data),
          pagination: {
            total: data.total,
            ...getPaginationByQuery(query),
          },
        }),
      },
    );
  };

  return {
    requestPromotions,
    requestPromotionsItem,
    requestPromotionsSearch,
    requestPromotionsByType,
    appendUrl,
    transformItems,
  };
};
